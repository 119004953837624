import {defineStore} from "pinia"
import {ref} from "vue"

const DEFAULT_AFFILIATE = 'organic'

export const useAffiliateStore = defineStore('AffiliateStore', () => {

    const affiliate = ref(DEFAULT_AFFILIATE)

    const setAffiliate = (_affiliate) => {
        affiliate.value = _affiliate || affiliate.value || DEFAULT_AFFILIATE
    }

    return {
        affiliate,
        setAffiliate
    }
}, {
    persist: [
        {
            paths: [
                'affiliate'
            ]
        }
    ]
})
