export default function UseHelpers() {
    const formatDate = (date, options = {
        day: '2-digit',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit'
    }) =>
        date ? new Intl.DateTimeFormat('en', options).format(new Date(date)) : ''

    const formatSeconds = seconds => `${Math.floor(seconds / 3600).toString().padStart(2, '0')}H ${Math.floor((seconds % 3600) / 60).toString().padStart(2, '0')}M`

    const timeout = ms => new Promise(resolve => setTimeout(resolve, ms))

    return { formatDate, formatSeconds, timeout }
}
