<script setup>
import {computed, onMounted, ref} from "vue"
import {Carousel, Slide} from "vue3-carousel"

import {usePromotionsStore} from "@shared/stores/PromotionsStore"

const props = defineProps({
    section: {
        type: String,
        required: true,
    },
})

const promotionsStore = usePromotionsStore()

const bannersCarousel = ref(null)

const banners = computed(() => promotionsStore.filterBanners(props.section))

onMounted(() => {
    promotionsStore.fetchBanners()
})
</script>

<template>
    <div id="divImageSlider">
        <div class="imageSliderComponent">
            <div class="divImageScroller">
                <Carousel
                    ref="bannersCarousel"
                    :items-to-show="1"
                    :autoplay="banners.length > 1 ? 5000 : 0"
                    :pauseAutoplayOnHover="true"
                    :wrapAround="true"
                    :transition="1000"
                >
                    <template v-for="banner in banners" :key="`banner-${banner.id}`">
                        <slide>
                            <a class="aSliderItem" :href="banner.url_link">
                                <div class="divSliderItem">
                                    <img
                                        v-lazy="banner.image"
                                        :srcset="`${banner.image}, ${banner.image_mobile} 600w`"
                                        class="imgSliderImage"
                                        :alt="banner.title"
                                        style="max-width: 100%"
                                    />
                                </div>
                            </a>
                        </slide>
                    </template>
                    <template
                        v-if="promotionsStore.banners?.length > 1"
                        #addons
                    >
                        <div @click="() => bannersCarousel.prev()" class="btnPrevious show"></div>
                        <div @click="() => bannersCarousel.next()" class="btnNext show"></div>
                    </template>
                </Carousel>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    #divImageSlider {
        width: unset !important;
    }
    .carousel__slide {
        display: flex;
        .aSliderItem {
            flex: 1;
            display: flex;
            .divSliderItem {
                flex: 1;
                img {
                    aspect-ratio: 1920/498;
                    object-fit: cover !important;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .carousel__slide {
            .aSliderItem {
                .divSliderItem {
                    img {
                        aspect-ratio: 3/2;
                    }
                }
            }
        }
    }
</style>
