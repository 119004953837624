<script setup>

import { vElementVisibility } from '@vueuse/components'
import {useBreakpoints} from '@vueuse/core'
import {computed, onMounted, reactive, watch} from "vue"

import GameItem from "@shared/components/molecules/GameItem.vue"
import {useGameStore} from "@shared/stores/GameStore"
import {useGameFilterProps} from "@shared/composables/useGameFilterProps";

const props = defineProps({
    ...useGameFilterProps(),
    group: {
        type: String,
        required: true
    },
    subCategory: {
        type: String,
        default: ''
    },
    product: { // ~game provider
        type: String,
        default: ''
    },
    url: {
        type: [String, Object],
        required: false,
        default: () => ''
    },
    showAll: {
        type: Boolean,
        default: () => false
    },
    displayAmounts: {
        type: Object,
        required: false,
        default: () => ({xl: 10, md: 8, sm: 6})
    },
    displayAmountsFixed: {
        type: Boolean,
        default: () => false
    },
    displayGameSpecials: {
        type: Boolean,
        default: () => false
    },
    breakpoints: {
        type: Object,
        default: () => ({sm: 800, md: 1480, xl: 1900})
    }
})

const gameStore = useGameStore()
const breakpoints = useBreakpoints(props.breakpoints)

const state = reactive({
    amountOfGames: 6,
    offset: 0,
    games: []
})

const calculateAmountOfGamesToDisplay = () => {
    if(props.showAll) {
        return 0
    }

    const {xl, md, sm} = props.displayAmounts

    if (breakpoints.greaterOrEqual('xl').value) {
        return xl
    }

    if (breakpoints.greaterOrEqual('md').value) {
        return md
    }

    return sm
}

const games = computed(() => gameStore.getGamesByCategory({
    category: props.group,
    subCategory: props.subCategory,
    product: props.product,
    limit: state.amountOfGames,
    offset: state.offset,
    filter: props.filter,
    sortBy: props.sortBy,
}))

const handleShowMore = () => {
    if (!props.displayAmountsFixed) {
        state.amountOfGames += calculateAmountOfGamesToDisplay()
    }
}

watch(
    () => breakpoints.current().value,
    () => {
        state.amountOfGames = calculateAmountOfGamesToDisplay()
    }
)

watch(
    () => props.filter,
    () => {
        state.amountOfGames = calculateAmountOfGamesToDisplay()
    }
)

onMounted(() => {
    state.amountOfGames = calculateAmountOfGamesToDisplay()
})
</script>

<template>
    <div
        :class="{
            gameslist: true,
            searchresults: filter,
            noresults: filter && games.count.all === 0
        }"
    >
        <p v-if="filter && games.count.all === 0" id="nosearchresultsmsg">
            Sorry, your search did not find any matching game, please try again.
        </p>
        <template v-else>
            <template v-if="displayGameSpecials">
                <div class="gameitem_special">
                    <GameItem
                        v-for="game in games.items.slice(0,3)"
                        :key="game.id"
                        :game="game"
                    />
                </div>
                <GameItem
                    v-for="game in games.items.slice(3)"
                    :key="game.id"
                    :game="game"
                />
            </template>

            <GameItem v-else
                v-for="game in games.items"
                :key="game.id"
                :game="game"
            />
        </template>
    </div>

    <div v-if="url" class="d-flex justify-content-center m-0 pt-5">
        <router-link :to="url">
            <button>SHOW MORE</button>
        </router-link>
    </div>

    <div
        v-else-if="!displayAmountsFixed && (games.items.length && games.count.all > games.items.length)"
        class="d-flex justify-content-center m-0 pt-5"
        v-element-visibility="handleShowMore"
    >
        <button @click="handleShowMore">SHOW MORE</button>
    </div>

</template>

<style scoped>

</style>
